// //create an interceptor which 







// http.interceptors.request.use(
//     function (config) {
        
//         const token='eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6InlfNG9tNjQ4RnZfV0pfOTBzSi03eiJ9.eyJpc3MiOiJodHRwczovL2xvZ2ljYWwtZG5zLnVzLmF1dGgwLmNvbS8iLCJzdWIiOiJnb29nbGUtb2F1dGgyfDExNTMwMzcxNTY4Njk1NjE3Mjg5MiIsImF1ZCI6WyJodHRwczovL2xvZ2ljYWwtZG5zLWFwaS5jb20iLCJodHRwczovL2xvZ2ljYWwtZG5zLnVzLmF1dGgwLmNvbS91c2VyaW5mbyJdLCJpYXQiOjE2OTM0ODU0MDAsImV4cCI6MTY5MzU3MTgwMCwiYXpwIjoiZVRoNVdmUW1uc1JRUFRBekoxVWVTY3BMdHdURHBmNjAiLCJzY29wZSI6Im9wZW5pZCBwcm9maWxlIGVtYWlsIiwicGVybWlzc2lvbnMiOlsiYWxsOmRhdGFzdG9yZSIsImFsbDpvdGhlcnMiLCJhbGw6c2V0dGluZ3MiLCJhbGw6c3R1ZGlvIiwiYWxsOnRlc3RzdWl0ZSJdfQ.cEhrH6qqojra0GCsZAkY5Tpd6XEnZ8QWcGPUa2jfMgrCcJUz_TWtFcRqTqOVCkDDTozVT9R_PubMCUYIfuJBdkOdgZBOLcfWJjK9GfjEH2_4XZKkOW0hFZNFDJkQxgd_LLqFn0NpdE-zHpPSe2oOQsWSTGMXW5iHnl6ylpAnopKFd2s1IjtHP5zo-P9YUcjJGTWHhO-n9hVRE8OQ2SV-ZwkqlcJL-lKjlay5GiZpcFsdwbC-J9ylcB7OX0-dK7yECJhw9jARQHsKYbAbXTNQeskrdeGbB9Uo4--utmVnhID7Omf3NyOMA53CqZM4bQwsjLZSLAbWOoDUipo0YS5TXA';
//         if (token) {
//             config.headers.Authorization = `Bearer ${token}`;
//         }
//         return config;
//     }
// );

// export default http;

import axios from "axios";

// const instance = axios.create({
//     baseURL: "http://128.199.16.44:8085",
// });


// const instance = axios.create({
//     baseURL: "https://api.devneural.com",
// });

// https://api.devneural.com


const instance = axios.create({
  baseURL: "http://127.0.0.1:8085",
});

class AuthInterceptor {
    constructor() {
      this.getAccessTokenSilently = undefined;
      this.token = undefined;
      this.getIdTokenClaims=undefined;
    }
    setAuthGetter(getAccessTokenSilently,getIdTokenClaims) {
      this.getAccessTokenSilently = getAccessTokenSilently;
      this.getIdTokenClaims=getIdTokenClaims;
    }
    
  
    async intercept(config) {
        if(!this.getAccessTokenSilently){
            return config;
        }
      try {
        const token = await this.getAccessTokenSilently();
        const idTokenClaims=await this.getIdTokenClaims();
        config.headers['Authorization'] = `Bearer ${token}`;
        config.headers['ID-Token'] = idTokenClaims.__raw;

      } catch (e) {
        console.log(e);
      }
      return config;
    }
  }
  
  export const authInjector = new AuthInterceptor()
  instance.interceptors.request.use((config) => authInjector.intercept(config));
  export const http = instance;